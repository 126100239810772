import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/abstract-design-background-smooth-flowing-lines.jpg";
import backgroundImageOne from "../../assets/down.jpeg";
import { DownOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space, Tooltip } from "antd";
import NewLogo from "../../assets/Skill-Stream-Jobs.gif";
import { Upload } from "antd";
import { Server } from "App";
import axios from "axios";
import { LoaderIcon, Toaster, toast } from "react-hot-toast";

const poolQuestions = [
  "Explain a project you worked on and the technologies used.",
  "How do you approach debugging a complex issue?",
  "What are the key principles of object-oriented programming?",
  "Describe a challenging problem you faced and how you solved it.",
  "How do you stay updated with the latest technology trends?",
  "Explain a data project you have worked on and the tools you used.",
  "How do you handle missing or inconsistent data?",
  "Describe a time when your data analysis led to a business decision.",
  "What statistical methods are you familiar with?",
  "How do you validate the results of your models?",
  "Describe a product you managed and the process you followed.",
  "How do you prioritize features for a product?",
  "Explain how you handle disagreements with stakeholders.",
  "What metrics do you use to measure product success?",
  "How do you approach product roadmapping?",
  "Describe a design project you worked on and the tools you used.",
  "How do you conduct user research?",
  "What is your process for creating wireframes and prototypes?",
  "How do you handle feedback from users and stakeholders?",
  "What design principles do you follow?",
  "Explain a marketing campaign you developed and its impact.",
  "How do you analyze the effectiveness of your marketing strategies?",
  "What tools do you use for market research?",
  "Describe a time when you had to pivot your marketing strategy.",
  "How do you stay updated with marketing trends and tools?",
];

const getRandomQuestions = (numQuestions) => {
  const shuffled = poolQuestions.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numQuestions);
};

const SetupForm = () => {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [numQuestions, setNumQuestions] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({ jobTitle: "", numQuestions: "" });
  const [questionOptions, setQuestionOptions] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [loader, setLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    setQuestionOptions(
      Array.from({ length: poolQuestions.length }, (_, i) => i + 1)
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let isValid = true;
      const newErrors = { jobTitle: "", jobDescription: "", resume: "" };

      if (!jobTitle) {
        newErrors.jobTitle = "Please enter a job title.";
        isValid = false;
      }
      if (!jobDescription) {
        newErrors.jobDescription = "Job Description Required.";
        isValid = false;
      }
      if (attachment.length === 0 || attachment?.status === "removed") {
        newErrors.resume = "Please upload resume";
        isValid = false;
      }
      if (numQuestions < 1 || numQuestions > poolQuestions.length) {
        newErrors.numQuestions = `Please enter a number between 1 and ${poolQuestions.length}.`;
        isValid = false;
      }

      if (isValid) {
        setSubmitting(true);
        // const questions = getRandomQuestions(numQuestions);
        const formData = new FormData();
        formData.append("file", attachment);
        formData.append("jobTitle", jobTitle);
        formData.append("jobDescription", jobDescription);
        const { data } = await axios.post(
          `${Server}/api/v1/practiceInterview/upload`,
          formData
        );
        console.log(data);

        // let payload = {
        //   resume_text: data?.resume_text,
        //   job_description_text: data?.job_description_text,
        //   instructions: [],
        // };
        // console.log(payload);

        // const response = await axios.post(
        //   `${Server}/api/v1/practiceInterview/startInterview`,
        //   payload
        // );
        // console.log(response.data);
        setSubmitting(false);

        navigate("/practical-interview", {
          state: {
            jobTitle,
            resume_text: data?.resume_text,
            job_description_text: data?.job_description_text,
          },
        });
      } else {
        setErrors(newErrors);
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      toast.error("Internal Server Error");
    }
  };

  const handleAIComplete = () => {
    // AI completion logic for job description can be added here
    // Placeholder code to show a simple alert
    alert("AI is generating job description...");
  };
  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  // console.log(attachment);

  const props = {
    onChange({ file }) {
      // When the file is selected, update the state with the file details
      // console.log("File selected:", file);
      setErrors((prev) => ({ ...prev, resume: "" }));
      setAttachment(file); // Store the file locally
    },
    beforeUpload: (file) => {
      // This prevents the automatic upload
      setAttachment(file); // Store the file in state
      return false; // Prevent the file from being uploaded automatically
    },
    onRemove: (file) => {
      // This prevents the automatic upload
      console.log("removed");

      setAttachment([]); // Store the file in state
    },
  };
  const completeAI = async () => {
    console.log("ai");

    try {
      let isValid = true;
      const newErrors = { jobTitle: "", numQuestions: "" };
      if (!jobTitle) {
        // toast.error("Please select a job title");
        // setJobTitleError("Job title is required");
        newErrors.jobTitle = "Please enter a job title.";
        isValid = false;
      }
      if (isValid) {
        setLoader(true);
        let formData = { jobTitle: jobTitle };
        const { data } = await axios.post(
          `${Server}/api/v1/parser/generate/description`,
          formData
        );
        // console.log(data);
        setJobDescription(data?.jobDescription);
        setErrors((prev) => ({ ...prev, jobDescription: "" }));
        // setJobSkills(data?.skills);
        setLoader(false);
      } else {
        setErrors(newErrors);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error("Internal Serer Error");
    }
  };
  return (
    <div style={{ display: "flex", width: "100%", height: "100vh" }}>
      <Toaster position="top-center" reverseOrder={false} />
      {/* Left Side - Image with Overlay */}
      <div
        style={{
          flex: "1",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        {/* <img
                      src={NewLogo}
                      style={{ height: "60px" }}
                      className="logo-images"
                      alt=""
                      title=""
                    />  */}
        {/* Overlay */}
        <div
          style={{
            position: "absolute",

            // background: "rgba(0, 0, 0, 0.5)",
            background: "rgba(255, 255, 255, 0.5)",
            borderRadius: "20px",
            color: "#fff",
            maxWidth: "500px",
            // height:"100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // textAlign: "center",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          {/* <h2
            style={{
              fontSize: "32px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Welcome to the Practice Interview Assessment
          </h2>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#fff",
            }}
          >
            Prepare for your next big opportunity with confidence! Our
            AI-powered platform helps you simulate real-world interview
            scenarios, providing you with instant feedback and personalized tips
            to sharpen your skills. Whether you're prepping for your first
            interview or a seasoned professional aiming to refine your
            responses, we’ve got you covered.
          </p> */}
          {/* <img
                      src={NewLogo}
                      style={{ height: "60px" }}
                      className="logo-images"
                      alt=""
                      title=""
                    /> */}
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "20px",
              textAlign: "center",
              color: "red",
            }}
          >
            Ace your next interview
          </h2>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#000",
            }}
          >
            Prepare for your next big opportunity with confidence! Our
            AI-powered platform helps you simulate real-world interview
            scenarios, providing you with instant feedback and personalized tips
            to sharpen your skills. Whether you're prepping for your first
            interview or a seasoned professional aiming to refine your
            responses, we’ve got you covered.
          </p>
          <p
            style={{
              fontSize: "15px",
              lineHeight: "1.6",
              marginBottom: "20px",
              fontWeight: "bold",
              color: "red",
            }}
          >
            What can you expect?
          </p>
          <ul
            style={{
              fontSize: "14px",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#000",
              fontWeight: "bold",
            }}
          >
            <li>
              <b style={{ color: "blue" }}>Realistic Interview Questions:</b>{" "}
              Practice with commonly asked questions tailored to your industry
              or role.
            </li>
            <li>
              <b style={{ color: "blue" }}>AI-Powered Feedback:</b> Receive
              instant insights on your communication, body language, and overall
              performance.
            </li>
            <li>
              <b style={{ color: "blue" }}>Customizable Sessions:</b> Choose
              your difficulty level, interview type, and time limits to match
              your needs.
            </li>
            <li>
              <b style={{ color: "blue" }}>Track Your Progress:</b> Review your
              performance over time and identify areas for improvement.
            </li>
          </ul>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "1.6",
              // fontWeight:"bold",
              color: "blue",
              // marginBottom: "20px",
            }}
          >
            Get started today and step into your next interview feeling
            confident, prepared, and polished!
          </p>
        </div>
      </div>

      {/* Right Side - Content and Form */}
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          boxSizing: "border-box",
          backgroundImage: `url(${backgroundImageOne})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
        }}
      >
        {/* <Dropdown
          menu={{
            items,
            onClick,
          }}
        >
          <Button onClick={(e) => e.preventDefault()}>
            <Space>
              Packages
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown> */}
        <br />
        {!showForm ? (
          <div
            style={{
              maxWidth: "600px",
              textAlign: "left",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* <h2
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "20px",
                textAlign:"center",
              }}
            >
              Ace your next interview
            </h2>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#000",
              }}
            >
              Prepare for your next big opportunity with confidence! Our
              AI-powered platform helps you simulate real-world interview
              scenarios, providing you with instant feedback and personalized
              tips to sharpen your skills. Whether you're prepping for your
              first interview or a seasoned professional aiming to refine your
              responses, we’ve got you covered.
            </p>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              What can you expect?
            </p>
            <ul
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              <li>
                <b>Realistic Interview Questions:</b> Practice with commonly
                asked questions tailored to your industry or role.
              </li>
              <li>
                <b>AI-Powered Feedback:</b> Receive instant insights on your
                communication, body language, and overall performance.
              </li>
              <li>
                <b>Customizable Sessions:</b> Choose your difficulty level,
                interview type, and time limits to match your needs.
              </li>
              <li>
                <b>Track Your Progress:</b> Review your performance over time
                and identify areas for improvement.
              </li>
            </ul>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginBottom: "20px",
              }}
            >
              Get started today and step into your next interview feeling
              confident, prepared, and polished!
            </p> */}
            <button
              onClick={() => setShowForm(true)}
              style={{
                backgroundColor: "#1d4ed8",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Start Interview
            </button>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              maxWidth: "600px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Interview Setup
            </h2>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Job Title
              </label>
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => {
                  setJobTitle(e.target.value);
                  setErrors((prev) => ({ ...prev, jobTitle: "" }));
                }}
                placeholder="Enter job title"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: `1px solid ${errors.jobTitle ? "#e74c3c" : "#ccc"}`,
                }}
              />
              {errors.jobTitle && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.jobTitle}
                </p>
              )}
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Job Description
              </label>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={completeAI}
                  disabled={loader}
                  type="button"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    border: "none",
                    cursor: "pointer",
                    transition: "background-color 0.3s, transform 0.2s",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                  }}
                >
                  {loader ? "Fetching...." : "Complete with AI"}
                </button>
              </div>
              <textarea
                value={jobDescription}
                onChange={(e) => {
                  setJobDescription(e.target.value);
                  setErrors((prev) => ({ ...prev, jobDescription: "" }));
                }}
                placeholder="Enter job description"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  height: "100px",
                  resize: "vertical",
                  overflow: "auto",
                }}
              />
              {errors.jobDescription && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.jobDescription}
                </p>
              )}
            </div>

            {/* <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Number of Questions
              </label>
              <input
                type="number"
                min="1"
                max={poolQuestions.length}
                value={numQuestions}
                onChange={(e) => {
                  setNumQuestions(parseInt(e.target.value, 10));
                  setErrors((prev) => ({ ...prev, numQuestions: "" }));
                }}
                placeholder={`1-${poolQuestions.length}`}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  border: `1px solid ${
                    errors.numQuestions ? "#e74c3c" : "#ccc"
                  }`,
                }}
              />
              {errors.numQuestions && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.numQuestions}
                </p>
              )}
            </div> */}
            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: "700",
                }}
              >
                Upload Resume
              </label>
              <Upload {...props}>
                <Button
                  size="large"
                  icon={<UploadOutlined />}
                  // disabled={
                  //   attachment.length != 0
                  // }
                >
                  Upload
                </Button>
              </Upload>
              {errors.resume && (
                <p style={{ color: "#e74c3c", marginTop: "5px" }}>
                  {errors.resume}
                </p>
              )}
            </div>
            <button
              type="submit"
              style={{
                backgroundColor: "#1d4ed8",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "10px",
                border: "none",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                fontSize: "15px",
                fontWeight: "bold",
              }}
              disabled={submitting}
            >
              {submitting ? "Generating ...." : "Generate Questions"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SetupForm;
