import React from "react";
import "./AiFitmentAnalysis.css"; // Import the CSS file for styling
import img from "../../assets/executives-working-hard-with-tablet.jpg";

const AiFitmentAnalysis = () => {
  return (
    <div className="fitment-analysis-form">
      <div className="form-image">
        {/* Add your image here */}
        <img src={img} alt="Fitment Analysis" />
      </div>
      <div className="form-container">
        <h2>AI Fitment Analysis</h2>
        <form>
          <label htmlFor="jobTitle">Job Title</label>
          <input type="text" id="jobTitle" name="jobTitle" required />

          <label htmlFor="jobDescription">Job Description</label>
          <textarea
            id="jobDescription"
            name="jobDescription"
            rows="4"
            required
          ></textarea>

          <label htmlFor="keyResponsibilities">Key Responsibilities</label>
          <textarea
            id="keyResponsibilities"
            name="keyResponsibilities"
            rows="4"
            required
          ></textarea>

          <label htmlFor="resumeUpload">Upload Resume</label>
          <input
            type="file"
            id="resumeUpload"
            name="resumeUpload"
            accept=".pdf,.doc,.docx"
            required
          />

          <button
            style={{
              display:
                "inline-block" /* Ensures proper spacing and hover effects */,
              marginTop: "1.5rem" /* Equivalent to Tailwind's mt-6 */,
              marginBottom: "1rem" /* Equivalent to Tailwind's mb-4 */,
              fontSize: "0.875rem" /* Equivalent to Tailwind's text-sm */,
              borderRadius:
                "9999px" /* Equivalent to Tailwind's rounded-full */,
              padding:
                "0.5rem 1rem" /* Adding padding for better clickability */,
              boxShadow:
                "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
              transition:
                "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
              borderRadius: "10px" /* Custom border radius */,
              background:
                "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
              color: "#fff" /* Text color */,
              cursor: "pointer" /* Change cursor on hover */,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.background =
                "linear-gradient(to right, #4299e1, #0056a1)";
              e.currentTarget.style.boxShadow =
                "0 6px 20px rgba(66, 153, 225, 0.7)";
              e.currentTarget.style.transform = "translateY(-2px)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.background =
                "linear-gradient(to right, #4299e1, #0070f3)";
              e.currentTarget.style.boxShadow =
                "0 4px 15px rgba(66, 153, 225, 0.5)";
              e.currentTarget.style.transform = "translateY(0)";
            }}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AiFitmentAnalysis;
