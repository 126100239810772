import { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from "jquery";
import GlobalStyles from "styles/GlobalStyles";
// // import { css } from "styled-components/macro";  //eslint-disable-line
// Components & pages
import Header from "./Components/Header/Header";
// import Header3 from "./Components/Header/Header3";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Auth/Login";
import Footer from "./Components/Footer/Footer";
import Footer1 from "./Components/Footer1/Footer1";
import Header1 from "./Components/Header/Header1";
import Register from "./Pages/Auth/Register";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import JobList from "./Pages/Find-Job/JobList";
import JobSingle from "./Pages/Find-Job/JobSingle";
import Dashboard from "./Pages/Employers/Employers-Dashboard/Dashboard";
import EmployersList from "./Pages/Employers/EmployersList";
import EmployersSingle from "./Pages/Employers/EmployersSingle";
import CandidatesList from "./Pages/Candidates/CandidatesList";
import CandidatesSingle from "./Pages/Candidates/CandidatesSingle";
import EmployerSidebar from "./Components/Sidebar/EmployerSidebar";
import CandidatesSidebar from "./Components/Sidebar/CandidatesSidebar";
import CandidateDashboard from "./Pages/Candidates/Candidates-Dashboard/Dashboard";
import VideoAssessment from "Components/VideoAssessment/VideoAssessment";
import DemoVideoAssessment from "./Components/DemoVideoAssessment/DemoVideoAssessment";
import AiFitmentAnalysis from "Components/AiFitmentAnalysis/AiFitmentAnalysis";
import AiJobSearch from "./Pages/Candidates/AiJobSearch";
//Candidates Dashboard
import AppliedJobs from "./Pages/Candidates/Candidates-Dashboard/AppliedJobs";
import ChangePassword from "./Pages/Candidates/Candidates-Dashboard/ChangePassword";
import CVManager from "./Pages/Candidates/Candidates-Dashboard/CVManager";
import JobAlerts from "./Pages/Candidates/Candidates-Dashboard/JobAlerts";
import Messages from "./Pages/Candidates/Candidates-Dashboard/Messages";
import MyResume from "./Pages/Candidates/Candidates-Dashboard/MyResume";
import Packages from "./Pages/Candidates/Candidates-Dashboard/Packages";
import CandidatesProfile from "./Pages/Candidates/Candidates-Dashboard/Profile";
import ShortlistedJobs from "./Pages/Candidates/Candidates-Dashboard/ShortlistedJobs";
import ViewProfileCandidates from "./Pages/Candidates/Candidates-Dashboard/ViewProfile";
import ProfileViewCandiates from "./Pages/Candidates/Candidates-Dashboard/ProfileView";
import PracticeAssessment from "./Pages/Candidates/Candidates-Dashboard/PracticeAssessment";
// import img from "./assets/favicon1.png";

//Employers Dashboard
import Applicants from "./Pages/Employers/Employers-Dashboard/Applicants";
import ManageJobs from "./Pages/Employers/Employers-Dashboard/ManageJobs";
import PostJob from "./Pages/Employers/Employers-Dashboard/PostJob";
import CompanyProfile from "./Pages/Employers/Employers-Dashboard/Profile";
import ResumeAlerts from "./Pages/Employers/Employers-Dashboard/ResumesAlerts";
import ShortlistedResumes from "./Pages/Employers/Employers-Dashboard/Shortlisted";
import ViewEmployerSProfile from "./Pages/Employers/Employers-Dashboard/ViewProfile";
import EmployerPackages from "./Pages/Employers/Employers-Dashboard/Packages";
import EmployersMessages from "./Pages/Employers/Employers-Dashboard/Messages";
import EmployersChangePassword from "./Pages/Employers/Employers-Dashboard/ChangePassword";
import EmployerProfileView from "./Pages/Employers/Employers-Dashboard/EmployerProfileView";
import EditPostJob from "./Pages/Employers/Employers-Dashboard/EditPostJob";
import BlogDetails from "./Pages/Blog/BlogDetails";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'jquery/dist/jquery.min.js';
import useScript from "./Hooks/useScript";
import { useNavigate } from "react-router-dom";
import Success from "./Components/Success/Success";
import { isTokenExpired } from "./utils/auth";
import TermsAndCondition from "./Pages/TermsAndCondition";
import Pricing from "./Pages/Pricing/Pricing";
import PrivacyCenter from "./Pages/PrivacyCenter";
import SecurityCenter from "./Pages/SecurityCenter";
import HomeNew from "./Pages/HomeNew/AgencyLandingPage";
import EmpHome from "./Pages/EmployerPage/ServiceLandingPage";
import Blog from "./Pages/Blog/Blog";
import BlogIndex from "./Pages/Blog/BlogIndex";
import Community from "Components/Community/Community";
import HeaderEmp from "Components/Header/HeaderEmp";
import PricingSuccess from "Pages/Pricing/PricingSuccess";
import Cancel from "Pages/Pricing/Cancel";
import NewHomeBlack from "Pages/NewHomeBlack/NewHomeBlack";
import SavedResumes from "Pages/Employers/SavedResumes";
import AiResumeSearch from "Pages/Employers/AiResumeSearch";
import SetupForm from "./Components/PracticalInterview/SetupForm";
import PracticalAssessment from "Components/PracticalInterview/PracticalInterview";
import Demo from "./Components/VideoAssessment/DemoModal";
import NotFound from "Pages/NotFound";
import { ScrollProvider } from "./Components/ScrollContext"; // Import the provider
import FeedbackPage from "Components/VideoAssessment/FeedbackPage";
import PracticeInterviewLanding from "Components/PracticalInterview/PracticeInterviewLanding/PracticeInterviewLanding";

export const Server = process.env.REACT_APP_SERVER;

function App() {
  const [isLoading, setLoading] = useState(true);
  const [userType, setUserType] = useState(""); // "candidate" or "company"
  const scrollToPricing = useRef();
  console.log(Server);
  // Function to set user type
  const setUser = (type) => {
    setUserType(type);
  };
  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  }
  useEffect(() => {
    let token = localStorage.getItem("token");
    isTokenExpired(token);
  }, []);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      // window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    fakeRequest().then(() => {
      setLoading(false); // Set loading to false when loading is complete.
    });
  }, []);

  if (isLoading) {
    return (
      <div className="preloader">
        {/* <img className="preloader-img"  src={img} alt="" /> */}
      </div>
      // return (
      //   <div class="cloud cloud1">
      //     <div class="light1"></div>
      //     <img
      //       src="https://images.vexels.com/media/users/3/145795/isolated/preview/05cd33059a006bf49006097af4ccba98-plane-in-flight-by-vexels.png"
      //       className="flight-img"
      //     />
      //   </div>
    );
  }

  const candidatesRoutes = [
    {
      path: "/applied-jobs",
      element: <AppliedJobs />,
    },
    {
      path: "/change-password",
      element: <ChangePassword />,
    },
    {
      path: "/cv-manager",
      element: <CVManager />,
    },
    {
      path: "/practiceInterview",
      element: <PracticeAssessment />,
    },
    {
      path: "/job-alerts",
      element: <JobAlerts />,
    },
    {
      path: "/messages",
      element: <Messages />,
    },
    {
      path: "/my-resume",
      element: <MyResume />,
    },
    {
      path: "/packages",
      element: <Packages />,
    },
    {
      path: "/my-profile",
      element: <CandidatesProfile />,
    },
    {
      path: "/shortlisted-jobs",
      element: <ShortlistedJobs />,
    },
    {
      path: "/candidates-view-profile",
      element: <ViewProfileCandidates />,
    },
    {
      path: "/candidates-profile-view",
      element: <ProfileViewCandiates />,
    },
  ];

  const EmployersRoutes = [
    {
      path: "/applicants",
      element: <Applicants />,
    },
    {
      path: "/employer-change-password",
      element: <EmployersChangePassword />,
    },
    {
      path: "/manage-jobs",
      element: <ManageJobs />,
    },
    {
      path: "/resume-alerts",
      element: <ResumeAlerts />,
    },
    {
      path: "/employer-messages",
      element: <EmployersMessages />,
    },
    {
      path: "/post-Job",
      element: <PostJob />,
    },
    {
      path: "/employer-packages",
      element: <EmployerPackages />,
    },
    {
      path: "/company-profile",
      element: <CompanyProfile />,
    },
    {
      path: "/shortlisted-resumes",
      element: <ShortlistedResumes />,
    },
    {
      path: "/employer-view-profile",
      element: <ViewEmployerSProfile />,
    },
    {
      path: "/employer-profile-view",
      element: <EmployerProfileView />,
    },
    {
      path: "/edit-post/:jobId",
      element: <EditPostJob />,
    },
    {
      path: "/savedResume",
      element: <SavedResumes />,
    },
  ];

  // Empty dependency array ensures the effect runs only once on mount
  let token = localStorage.getItem("token");

  return (
    <>
      <GlobalStyles />
      <ScrollProvider>
        {" "}
        {/* Wrap the application with ScrollProvider */}
        <BrowserRouter>
          <Routes>
            {/* <Route
          path="/"
          element={
            <div>
              {" "}
              <Header />
              <Home />
              <Footer />
            </div>
          }
        /> */}

            <Route
              path="/black"
              element={
                <div>
                  {" "}
                  <Header />
                  <NewHomeBlack />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/practiceInterviewLanding"
              element={
                <div>
                  {" "}
                  <Header />
                  <PracticeInterviewLanding />
                  <Footer />
                </div>
              }
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/demo" element={<Demo />} />

            <Route
              path="/candiatesPage"
              element={
                <div>
                  {" "}
                  <Header />
                  <HomeNew />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/"
              element={
                <div>
                  {" "}
                  <HeaderEmp />
                  <EmpHome />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/aiFitment"
              element={
                <div>
                  {" "}
                  {/* <HeaderEmp /> */}
                  <AiFitmentAnalysis />
                  {/* <Footer /> */}
                </div>
              }
            />
            <Route
              path="/aiJobSearch"
              element={
                <div>
                  {" "}
                  {/* <HeaderEmp /> */}
                  <AiJobSearch />
                  {/* <Footer /> */}
                </div>
              }
            />

            {/*<Route
          path="/login"
          element={
            <div>
              {" "}
            
              <Login />
            </div>
          }
        />

        <Route
          path="/register"
          element={
            <div>
              {" "}
             
              <Register />
            </div>
          }
        />*/}

            {/* <Route
          path="*"
          element={
            <div>
              {" "}
              <Header />
              <Home />
              <Footer />
            </div>
          }
        /> */}

            <Route
              path="/about"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <About />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/videoAssessment/:userId/:id/:jobId"
              element={<VideoAssessment />}
            />
            <Route
              path="/demoVideoAssessment/:userId/:id/:jobId"
              element={<DemoVideoAssessment />}
            />
            <Route path="/feedbackForm" element={<FeedbackPage />} />
            <Route
              path="/practicalAssessment"
              element={
                <div>
                  {" "}
                  {/* <Header1 /> */}
                  <SetupForm />
                  {/* <Footer /> */}
                </div>
              }
            />

            <Route
              path="/practical-interview"
              element={<PracticalAssessment />}
            />

            <Route
              path="/blog"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <Blog />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/blog-details/:topic/:id"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <BlogDetails />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/community"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <Community />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/pricing"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <Pricing />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/pricing/success"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <PricingSuccess />
                </div>
              }
            />
            <Route
              path="/pricing/cancel"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <Cancel />
                </div>
              }
            />
            <Route path="/linkedIn/success" element={<Success />} />

            <Route
              path="/contact"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <Contact />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/termsAndCondition"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <TermsAndCondition />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/privacyCenter"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <PrivacyCenter />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/securityCenter"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <SecurityCenter />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/find-jobs"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <JobList />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/job-detail/:userId/:jobId"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <JobSingle />
                  <Footer />
                </div>
              }
            />

            <Route
              path="/employer-dashboard"
              element={
                <div className="dashboard">
                  {" "}
                  <Header1 />
                  <EmployerSidebar />
                  <Dashboard />
                </div>
              }
            />

            <Route
              path="/employers-list"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <EmployersList />
                </div>
              }
            />

            <Route
              path="employers/:id"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <EmployersSingle />
                </div>
              }
            />

            <Route
              path="/candidate-dashboard"
              element={
                <div className="dashboard">
                  {" "}
                  <Header1 />
                  <CandidatesSidebar />
                  <CandidateDashboard />
                </div>
              }
            />

            <Route
              path="/candidates-list"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <CandidatesList />
                </div>
              }
            />
            {/*    <Route
              path="/savedResume"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <SavedResumes />
                </div>
              }
            />*/}

            <Route
              path="/aiSearch"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <AiResumeSearch />
                </div>
              }
            />

            <Route
              path="/candidates-single/:id"
              element={
                <div>
                  {" "}
                  <Header1 />
                  <CandidatesSingle />
                </div>
              }
            />

            {candidatesRoutes?.map((e) => (
              <Route
                key={e}
                path={e.path}
                element={
                  <div className="dashboard">
                    <Header1 />
                    <CandidatesSidebar />
                    {e.element}
                  </div>
                }
              />
            ))}

            {EmployersRoutes?.map((e) => (
              <Route
                key={e}
                path={e.path}
                element={
                  <div className="dashboard">
                    <Header1 />
                    <EmployerSidebar />
                    {e.element}
                  </div>
                }
              />
            ))}
          </Routes>
        </BrowserRouter>
      </ScrollProvider>
    </>
  );
}

export default App;
