import React, { useEffect, useState } from "react";
import $ from "jquery";
import Login from "../../Pages/Auth/Login";
import Register from "../../Pages/Auth/Register";
import Resume from "../../Components/Header/Resume";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ConfirmModal from "../../Pages/Auth/ConfirmModal";
import ForgotPassword from "../../Pages/Auth/ForgotPassword";
import { Button, Drawer } from "antd";
import logo2 from "../../assets/unnamedBlack.png";
import logo1 from "../../assets/Skill-Stream-dark.jpg";
import NewLogo from "../../assets/Skill-Stream-Jobs.gif";

// import "popper.js";
// import "bootstrap";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu, Typography } from "antd";
import { googleLogout } from "@react-oauth/google";
import logo from "../../assets/generate-a-logo-with--S------Use-Green-and-White-color-combination-removebg-preview.png";

import avatar from "../../assets/images/resource/company-6.png";
import axios from "axios";
import { Server } from "../../App";
import toast from "react-hot-toast";
import UploadResume from "./UploadResume";
const Header3 = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [activePage, setActivePage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [uploadResume, setUploadResume] = useState(false);
  const [close, setClose] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [data, setData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [profileToastShown, setProfileToastShown] = useState(false);
  const location = useLocation();
  const getData = async () => {
    try {
      let userId = localStorage.getItem("UserId");
      let role = localStorage.getItem("Role");
      if (role === "Candidate") {
        const userData = await axios.get(
          `${Server}/api/v1/candidate/${userId}`
        );
        setData(userData?.data?.Candidate);

        // console.log("hi", userData?.data?.Candidate[0]?.profileCompleted);
        // const isProfileCompleted =
        //   userData?.data?.Candidate[0]?.profileCompleted;
        // if (!isProfileCompleted && !profileToastShown) {
        //   console.log("hi", userData?.data?.Candidate[0]?.profileCompleted);
        //   navigate("/my-profile");
        //   // toast.error("Please update your profile");
        //   // setProfileToastShown(true);
        // }
      } else {
        const empData = await axios.get(`${Server}/api/v1/employer/${userId}`);
        setData(empData.data.Employer);
        // console.log("hello", empData);
        // const isProfileCompleted = empData?.data?.Employer[0]?.profileCompleted;
        // if (!isProfileCompleted && !profileToastShown) {
        //   console.log("hi", empData?.data?.Employer[0]?.profileComplete);
        //   navigate("/my-profile");
        //   // toast.error("Please update your profile");
        //   // setProfileToastShown(true);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(data[0]?.plan);
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (
      role === "Candidate" &&
      data &&
      data.length > 0 &&
      data[0]?.profileCompleted
    ) {
      const isResumeUploaded = data[0].isResumeUploaded;
      // Show the modal only if isResumeUploaded is false
      setUploadResume(!isResumeUploaded);
    }
  }, [data]);
  useEffect(() => {
    // Update the current path whenever the location changes
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const drawer = document.getElementById("your-drawer-id"); // Replace 'your-drawer-id' with the actual ID of your Drawer component
      if (drawer && !drawer.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu state
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // const showResumeModal = () => {
  //   setModalOpen(true);
  // };

  const handleOk = () => {
    setModalOpen(false);
  };
  const showModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setClose(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleNavigationClick = (page) => {
    setActivePage(page);
    // Add any additional logic you want to perform when a navigation link is clicked
  };
  let role = localStorage.getItem("Role");
  // useEffect(() => {
  //   if (token === null) {
  //     showModal("login");
  //   }
  // }, []);
  useEffect(() => {
    function headerStyle() {
      var windowpos = $(window).scrollTop();
      var siteHeader = $(".main-header");
      var scrollLink = $(".scroll-to-top");
      var sticky_header = $(".main-header");

      if (windowpos > 1) {
        siteHeader.addClass("fixed-header animated slideInDown");
        scrollLink.fadeIn(300);
      } else {
        siteHeader.removeClass("fixed-header animated slideInDown");
        scrollLink.fadeOut(300);
      }
    }

    // Initial call
    headerStyle();

    // Scroll event listener
    $(window).scroll(() => {
      headerStyle();
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      $(window).off("scroll", headerStyle);
    };
  }, []);
  // useEffect(() => {
  //   if ($("#toggle-user-sidebar").length) {
  //     $("#toggle-user-sidebar, .dashboard-option a").on("click", function () {
  //       $("body").toggleClass("user-sidebar-active");
  //     });

  //     $(".sidebar-backdrop").on("click", function () {
  //       $("body").removeClass("user-sidebar-active");
  //     });
  //   }
  // }, []);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("Role");
    localStorage.removeItem("UserId");
    localStorage.removeItem("ProfileCompleted");
    localStorage.removeItem("Subscribed");
    localStorage.removeItem("linkedinAccessToken");
    // window.location.reload();
    navigate("/");
    setTimeout(() => {
      setIsModalOpen(true);
    }, 1000);
  };
  // const [text, setText] = useState("");
  // const fullText = "Where Skills and Opportunities align";

  // useEffect(() => {
  //   let currentIndex = 0;

  //   const interval = setInterval(() => {
  //     if (currentIndex <= fullText.length) {
  //       setText(fullText.substring(0, currentIndex));
  //       currentIndex++;
  //     } else {
  //       currentIndex = 0; // Reset currentIndex to restart typing animation
  //     }
  //   }, 100); // Adjust typing speed here (milliseconds)

  //   return () => clearInterval(interval);
  // }, []);

  const accountMenu = (
    <Menu className="user-sidebar navigation">
      <Menu.Item key="1">
        <Link className="link-option" to="/candidate-dashboard">
          {" "}
          <i class="la la-dashboard design"></i> Dashboard
        </Link>
      </Menu.Item>
      {/*<Menu.Item key="2">
        <Link className="link-option" to="/my-profile">
          <i class="la la-user-tie design"></i>My Profile
        </Link>
      </Menu.Item>*/}
      {/* <Menu.Item key="3">
        <Link className="link-option" to="/my-resume">
          <i class="la la-file-invoice design"></i>My Resume
        </Link>
    </Menu.Item>*/}
      <Menu.Item key="2">
        <Link className="link-option" to="/practiceInterview">
          <i class="la la-file-invoice design"></i>Practice Video Assessment
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link className="link-option" to="/applied-jobs">
          <i class="la la-briefcase design"></i> Applied Jobs{" "}
        </Link>
      </Menu.Item>

      <Menu.Item key="5">
        <Link className="link-option" to="/job-alerts">
          <i class="la la-bell design"></i>Job Alerts
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link className="link-option" to="/shortlisted-jobs">
          <i class="la la-bookmark-o design"></i>Shortlisted Jobs
        </Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link className="link-option" to="/cv-manager">
          <i class="la la-file-invoice design"></i> CV manager
        </Link>
      </Menu.Item>
      {/*<Menu.Item key="8">
        <Link className="link-option" to="/packages">
          <i class="la la-box design"></i>Packages
        </Link>
  </Menu.Item>*/}
      <Menu.Item key="9">
        <Link className="link-option" to="/messages">
          <i class="la la-comment-o design"></i>Messages
        </Link>
      </Menu.Item>
      {/*    <Menu.Item key="10">
        <Link className="link-option" to="/change-password">
          <i class="la la-lock design"></i>Change Password
        </Link>
</Menu.Item>*/}
      <Menu.Item key="11">
        <Link className="link-option" to="/candidates-profile-view">
          <i class="la la-user-alt design"></i>View Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="12">
        <a className="link-option" onClick={() => handleLogout()}>
          <i class="la la-sign-out design"></i>Logout
        </a>
      </Menu.Item>
      {/* <Menu.Item key="13">
        <Link className="link-option" to="/delete">
          <i class="la la-trash design"></i>Delete Profile
        </Link>
  </Menu.Item>*/}
    </Menu>
  );

  const employerMenu = (
    <Menu className="user-sidebar navigation">
      <Menu.Item key="1">
        <Link className="link-option" to="/employer-dashboard">
          {" "}
          <i class="la la-dashboard design"></i> Dashboard
        </Link>
      </Menu.Item>
      {/*  <Menu.Item key="2">
        <Link className="link-option" to="/company-profile">
          <i class="la la-user-tie design"></i>Company Profile
        </Link>
      </Menu.Item>*/}
      <Menu.Item key="3">
        <Link className="link-option" to="/post-job">
          <i class="la la-paper-plane design"></i>Post a New Job
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link className="link-option" to="/manage-jobs">
          <i class="la la-briefcase design"></i> Manage Jobs{" "}
        </Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link className="link-option" to="/applicants">
          <i class="la la-file-invoice design"></i> All Applicants
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link className="link-option" to="/shortlisted-resumes">
          <i class="la la-bookmark-o design"></i>Shortlisted Resumes
        </Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link className="link-option" to="/savedResume">
          <i class="la la-heart-o design"></i>Saved Resumes
        </Link>
      </Menu.Item>
      {/*<Menu.Item key="7">
        <Link className="link-option" to="/employer-packages">
          <i class="la la-box design"></i>Packages
        </Link>
  </Menu.Item>*/}
      <Menu.Item key="8">
        <Link className="link-option" to="/employer-messages">
          <i class="la la-comment-o design"></i>Messages
        </Link>
      </Menu.Item>
      <Menu.Item key="9">
        <Link className="link-option" to="/resume-alerts">
          <i class="la la-bell design"></i>Notifications
        </Link>
      </Menu.Item>
      {/*    <Menu.Item key="10">
        <Link className="link-option" to="/employer-change-password">
          <i class="la la-lock design"></i>Change Password
        </Link>
  </Menu.Item>*/}
      <Menu.Item key="11">
        <Link className="link-option" to="/employer-profile-view">
          <i class="la la-user-alt design"></i>View Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="12">
        <a className="link-option" onClick={() => handleLogout()}>
          <i class="la la-sign-out design"></i>Logout
        </a>
      </Menu.Item>
      {/* <Menu.Item key="13">
        <Link className="link-option" to="/delete">
          <i class="la la-trash design"></i>Delete Profile
        </Link>
  </Menu.Item>*/}
    </Menu>
  );
  const items = [
    {
      key: "1",
      label: "Item 1",
    },
    {
      key: "2",
      label: "Item 2",
    },
    {
      key: "3",
      label: "Item 3",
    },
  ];
  return (
    <div>
      <span class="header-span"></span>
      <header class="main-header">
        <div class="container-fluid">
          <div class="main-box">
            <div class="nav-outer">
              <div className="logo-box">
                {/* <div className="logo">
                  <a href="/">
                    <img
                      src={logo1}
                      style={{ height: "80px", marginBottom: "-10px" }}
                      className="logo-images1"
                      alt=""
                      title=""
                    />
                  </a>
                </div> */}

                <div className="logo" style={{ marginTop: "10px" }}>
                  <a href="/">
                    <img
                      src={NewLogo}
                      style={{ height: "50px" }}
                      className="logo-images"
                      alt=""
                      title=""
                    />
                    {/* <span
                      style={{
                        color: "#1E90FF",
                        fontSize: "18px",
                        fontWeight: 700,
                      }}
                    >
                      SkillStreamJobs
                    </span>
                    <span
                      style={{
                        color: "#00d0ea",
                        fontSize: "20px",
                        fontWeight: "900",
                      }}
                    >
                      .AI
                    </span> */}
                  </a>
                </div>
                {/* <div className="skills-logo3">{text}</div> */}
              </div>
              {token === null ? (
                <div>
                  <nav className="nav main-menu">
                    <ul className="navigation" id="navbar">
                      <div className="header-navigation">
                        <li
                          className={`dropdown ${
                            currentPath === "/" ? "current" : ""
                          }`}
                        >
                          <div className="custom-dropdown">
                            <div
                              style={{
                                display:
                                  "inline-block" /* Ensures proper spacing and hover effects */,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop:
                                  "0.9rem" /* Equivalent to Tailwind's mt-6 */,
                                marginBottom:
                                  "0.9rem" /* Equivalent to Tailwind's mb-4 */,
                                fontSize:
                                  "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                borderRadius:
                                  "9999px" /* Equivalent to Tailwind's rounded-full */,
                                padding:
                                  "0.5rem 1rem" /* Adding padding for better clickability */,
                                boxShadow:
                                  "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                transition:
                                  "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                borderRadius: "10px" /* Custom border radius */,
                                background:
                                  "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                color: "#fff" /* Text color */,
                                cursor: "pointer" /* Change cursor on hover */,
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0056a1)";
                                e.currentTarget.style.boxShadow =
                                  "0 6px 20px rgba(66, 153, 225, 0.7)";
                                e.currentTarget.style.transform =
                                  "translateY(-2px)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0070f3)";
                                e.currentTarget.style.boxShadow =
                                  "0 4px 15px rgba(66, 153, 225, 0.5)";
                                e.currentTarget.style.transform =
                                  "translateY(0)";
                              }}
                              onClick={() => {
                                const dropdown =
                                  document.querySelector(".dropdown-menu");
                                dropdown.classList.toggle("show");
                              }}
                            >
                              <span>Blogs</span>
                              <i className="dropdown-icon">▼</i>
                            </div>
                            <ul className="dropdown-menu">
                              <li
                                style={{
                                  display:
                                    "flex" /* Ensures proper spacing and hover effects */,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: "0.5rem",
                                  fontSize:
                                    "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                  borderRadius:
                                    "9999px" /* Equivalent to Tailwind's rounded-full */,
                                  padding:
                                    "0.5rem 1rem" /* Adding padding for better clickability */,
                                  boxShadow:
                                    "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                  transition:
                                    "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                  borderRadius:
                                    "10px" /* Custom border radius */,
                                  background:
                                    "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                  color: "#fff" /* Text color */,
                                  cursor:
                                    "pointer" /* Change cursor on hover */,
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.background =
                                    "linear-gradient(to right, #4299e1, #0056a1)";
                                  e.currentTarget.style.boxShadow =
                                    "0 6px 20px rgba(66, 153, 225, 0.7)";
                                  e.currentTarget.style.transform =
                                    "translateY(-2px)";
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.background =
                                    "linear-gradient(to right, #4299e1, #0070f3)";
                                  e.currentTarget.style.boxShadow =
                                    "0 4px 15px rgba(66, 153, 225, 0.5)";
                                  e.currentTarget.style.transform =
                                    "translateY(0)";
                                }}
                                // onClick={(e) => {
                                //   document.querySelector(
                                //     ".dropdown-header span"
                                //   ).textContent = e?.currentTarget.textContent;
                                //   document
                                //     .querySelector(".dropdown-menu")
                                //     .classList.remove("show");
                                // }}
                              >
                                <span onClick={() => navigate("/blog")}>
                                  Blog
                                </span>
                              </li>
                              <li
                                style={{
                                  display:
                                    "inline-block" /* Ensures proper spacing and hover effects */,
                                  marginTop:
                                    "0.5rem" /* Equivalent to Tailwind's mt-6 */,
                                  display:
                                    "flex" /* Ensures proper spacing and hover effects */,
                                  marginLeft: "0.5rem",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // marginBottom:
                                  //   "0.5rem" /* Equivalent to Tailwind's mb-4 */,
                                  fontSize:
                                    "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                  borderRadius:
                                    "9999px" /* Equivalent to Tailwind's rounded-full */,
                                  padding:
                                    "0.5rem 1rem" /* Adding padding for better clickability */,
                                  boxShadow:
                                    "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                  transition:
                                    "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                  borderRadius:
                                    "10px" /* Custom border radius */,
                                  background:
                                    "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                  color: "#fff" /* Text color */,
                                  cursor:
                                    "pointer" /* Change cursor on hover */,
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.background =
                                    "linear-gradient(to right, #4299e1, #0056a1)";
                                  e.currentTarget.style.boxShadow =
                                    "0 6px 20px rgba(66, 153, 225, 0.7)";
                                  e.currentTarget.style.transform =
                                    "translateY(-2px)";
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.background =
                                    "linear-gradient(to right, #4299e1, #0070f3)";
                                  e.currentTarget.style.boxShadow =
                                    "0 4px 15px rgba(66, 153, 225, 0.5)";
                                  e.currentTarget.style.transform =
                                    "translateY(0)";
                                }}
                                // onClick={(e) => {
                                //   document.querySelector(
                                //     ".dropdown-header span"
                                //   ).textContent = e.currentTarget.textContent;
                                //   document
                                //     .querySelector(".dropdown-menu")
                                //     .classList.remove("show");
                                // }}
                              >
                                <span onClick={() => navigate("/community")}>
                                  Community
                                </span>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li
                          className={`dropdown ${
                            currentPath === "/pricing" ? "current" : ""
                          }`}
                        >
                          <Link to="/pricing">
                            <span
                              style={{
                                display:
                                  "inline-block" /* Ensures proper spacing and hover effects */,
                                marginTop:
                                  "1rem" /* Equivalent to Tailwind's mt-6 */,
                                marginBottom:
                                  "1rem" /* Equivalent to Tailwind's mb-4 */,
                                fontSize:
                                  "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                borderRadius:
                                  "9999px" /* Equivalent to Tailwind's rounded-full */,
                                padding:
                                  "0.5rem 1rem" /* Adding padding for better clickability */,
                                boxShadow:
                                  "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                transition:
                                  "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                borderRadius: "10px" /* Custom border radius */,
                                background:
                                  "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                color: "#fff" /* Text color */,
                                cursor: "pointer" /* Change cursor on hover */,
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0056a1)";
                                e.currentTarget.style.boxShadow =
                                  "0 6px 20px rgba(66, 153, 225, 0.7)";
                                e.currentTarget.style.transform =
                                  "translateY(-2px)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0070f3)";
                                e.currentTarget.style.boxShadow =
                                  "0 4px 15px rgba(66, 153, 225, 0.5)";
                                e.currentTarget.style.transform =
                                  "translateY(0)";
                              }}
                            >
                              Pricing
                            </span>
                          </Link>
                        </li>
                        <li
                          className={`dropdown ${
                            currentPath === "/about" ? "current" : ""
                          }`}
                        >
                          <Link to="/about">
                            <span
                              style={{
                                display:
                                  "inline-block" /* Ensures proper spacing and hover effects */,
                                marginTop:
                                  "1rem" /* Equivalent to Tailwind's mt-6 */,
                                marginBottom:
                                  "1rem" /* Equivalent to Tailwind's mb-4 */,
                                fontSize:
                                  "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                borderRadius:
                                  "9999px" /* Equivalent to Tailwind's rounded-full */,
                                padding:
                                  "0.5rem 1rem" /* Adding padding for better clickability */,
                                boxShadow:
                                  "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                transition:
                                  "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                borderRadius: "10px" /* Custom border radius */,
                                background:
                                  "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                color: "#fff" /* Text color */,
                                cursor: "pointer" /* Change cursor on hover */,
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0056a1)";
                                e.currentTarget.style.boxShadow =
                                  "0 6px 20px rgba(66, 153, 225, 0.7)";
                                e.currentTarget.style.transform =
                                  "translateY(-2px)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0070f3)";
                                e.currentTarget.style.boxShadow =
                                  "0 4px 15px rgba(66, 153, 225, 0.5)";
                                e.currentTarget.style.transform =
                                  "translateY(0)";
                              }}
                            >
                              About
                            </span>
                          </Link>
                        </li>

                        <li
                          className={`dropdown ${
                            currentPath === "/contact" ? "current" : ""
                          }`}
                        >
                          <Link to="/contact">
                            <span
                              style={{
                                display:
                                  "inline-block" /* Ensures proper spacing and hover effects */,
                                marginTop:
                                  "1rem" /* Equivalent to Tailwind's mt-6 */,
                                marginBottom:
                                  "1rem" /* Equivalent to Tailwind's mb-4 */,
                                fontSize:
                                  "0.875rem" /* Equivalent to Tailwind's text-sm */,
                                borderRadius:
                                  "9999px" /* Equivalent to Tailwind's rounded-full */,
                                padding:
                                  "0.5rem 1rem" /* Adding padding for better clickability */,
                                boxShadow:
                                  "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                                transition:
                                  "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                                borderRadius: "10px" /* Custom border radius */,
                                background:
                                  "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                                color: "#fff" /* Text color */,
                                cursor: "pointer" /* Change cursor on hover */,
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0056a1)";
                                e.currentTarget.style.boxShadow =
                                  "0 6px 20px rgba(66, 153, 225, 0.7)";
                                e.currentTarget.style.transform =
                                  "translateY(-2px)";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.background =
                                  "linear-gradient(to right, #4299e1, #0070f3)";
                                e.currentTarget.style.boxShadow =
                                  "0 4px 15px rgba(66, 153, 225, 0.5)";
                                e.currentTarget.style.transform =
                                  "translateY(0)";
                              }}
                            >
                              Contact
                            </span>
                          </Link>
                        </li>
                      </div>
                      <div className="resume-button">
                        <button
                          onClick={() => navigate("/candiatesPage")}
                          className="theme-btn btn-style-two1"
                        >
                          <span className="submit-resume">For Job Seekers</span>
                        </button>
                      </div>
                      <li className="mm-add-listing">
                        <a href="job-post" className="theme-btn btn-style-one">
                          Job Post
                        </a>
                        <span>
                          <span className="contact-info">
                            <span className="phone-num">
                              <span>Call us</span>
                              <a href="tel:1234567890">123 456 7890</a>
                            </span>
                            <span className="address">
                              329 Queensberry Street, North Melbourne VIC <br />
                              3051, Australia.
                            </span>
                            <a
                              href="mailto:support@Skill Stream.com"
                              className="email"
                            >
                              support@Skill Stream.com
                            </a>
                          </span>
                          <span className="social-links">
                            <a href="#">
                              <span className="fab fa-facebook-f"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-twitter"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-instagram"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-linkedin-in"></span>
                            </a>
                          </span>
                        </span>
                      </li>
                    </ul>
                  </nav>
                  {/* {modalOpen && (
                    <div>
                      
                      <Resume
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        showResumeModal={showResumeModal}
                      />
                    </div>
                  )} */}
                </div>
              ) : (
                <nav className="nav main-menu">
                  {role === "Candidate" ? (
                    <ul className="navigation" id="navbar">
                      <div className="header-navigation">
                        {/* <li
                          className={`dropdown ${
                            currentPath === "/" ? "current" : ""
                          }`}
                        >
                          <Link to="/">
                            <span className="nav-link1">Home</span>
                          </Link>
                        </li>*/}

                        <li
                          className={`dropdown has-mega-menu ${
                            currentPath === "/find-jobs" ? "current" : ""
                          }`}
                          id="has-mega-menu"
                        >
                          <Link to="/find-jobs">
                            <span className="nav-link1">Find Jobs</span>
                          </Link>
                        </li>

                        <li
                          className={`dropdown ${
                            currentPath === "/employers-list" ? "current" : ""
                          }`}
                        >
                          <Link to="/employers-list">
                            <span className="nav-link1">Companies</span>
                          </Link>
                        </li>
                        <li
                          className={`dropdown ${
                            currentPath === "/employers-list" ? "current" : ""
                          }`}
                        >
                          <Link to="/aiJobSearch">
                            <span className="nav-link1">AI Job Search</span>
                          </Link>
                        </li>
                        <li
                          className={`dropdown ${
                            currentPath === "/candidate-dashboard"
                              ? "current"
                              : ""
                          }`}
                        >
                          <Link to="/candidate-dashboard">
                            <span className="nav-link1">Dashboard</span>
                          </Link>
                        </li>
                      </div>
                      <li className="mm-add-listing">
                        <a href="job-post" className="theme-btn btn-style-one">
                          Job Post
                        </a>
                        <span>
                          <span className="contact-info">
                            <span className="phone-num">
                              <span>Call us</span>
                              <a href="tel:1234567890">123 456 7890</a>
                            </span>
                            <span className="address">
                              329 Queensberry Street, North Melbourne VIC <br />
                              3051, Australia.
                            </span>
                            <a
                              href="mailto:support@Skill Stream.com"
                              className="email"
                            >
                              support@Skill Stream.com
                            </a>
                          </span>
                          <span className="social-links">
                            <a href="#">
                              <span className="fab fa-facebook-f"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-twitter"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-instagram"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-linkedin-in"></span>
                            </a>
                          </span>
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <ul className="navigation" id="navbar">
                      {/*   <li className="current dropdown">
                      //   <a href="/">
                      //     <span>Home</span>
                      //   </a>
                  </li>*/}
                      {data[0]?.plan === "Recommended" ? (
                        <>
                          <li
                            className={`dropdown ${
                              currentPath === "/candidates-list"
                                ? "current"
                                : ""
                            }`}
                          >
                            <a href="/candidates-list">
                              <span className="nav-link1">Resume Search</span>
                            </a>
                          </li>
                          <li
                            className={`dropdown ${
                              currentPath === "/aiSearch" ? "current" : ""
                            }`}
                          >
                            <a href="/aiSearch">
                              <span className="nav-link1">
                                AI Resume Search
                              </span>
                            </a>
                          </li>
                        </>
                      ) : null}
                      {/* <li
                        className={`dropdown ${
                          currentPath === "/savedResume" ? "current" : ""
                        }`}
                      >
                        <a href="/savedResume">
                          <span className="nav-link1">Saved Resume</span>
                        </a>
                      </li>*/}
                      <li
                        className={`dropdown ${
                          currentPath === "/employer-dashboard" ? "current" : ""
                        }`}
                      >
                        <a href="/employer-dashboard">
                          <span className="nav-link1">Dashboard</span>
                        </a>
                      </li>
                      <li className="mm-add-listing">
                        <a href="job-post" className="theme-btn btn-style-one">
                          Job Post
                        </a>
                        <span>
                          <span className="contact-info">
                            <span className="phone-num">
                              <span>Call us</span>
                              <a href="tel:1234567890">123 456 7890</a>
                            </span>
                            <span className="address">
                              329 Queensberry Street, North Melbourne VIC <br />
                              3051, Australia.
                            </span>
                            <a
                              href="mailto:support@Skill Stream.com"
                              className="email"
                            >
                              support@Skill Stream.com
                            </a>
                          </span>
                          <span className="social-links">
                            <a href="#">
                              <span className="fab fa-facebook-f"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-twitter"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-instagram"></span>
                            </a>
                            <a href="#">
                              <span className="fab fa-linkedin-in"></span>
                            </a>
                          </span>
                        </span>
                      </li>
                    </ul>
                  )}
                </nav>
              )}
            </div>

            {token === null ? (
              <div className="outer-box">
                <div className="btn-box">
                  <button
                    className="theme-btn 
                    btn-style-ffive call-modal"
                    // href="/login"
                    onClick={() => showModal("login")}
                  >
                    Login / Register
                  </button>
                  {forgotModal && (
                    <ForgotPassword
                      forgotModal={forgotModal}
                      setForgotModal={setForgotModal}
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      setModalType={setModalType}
                    />
                  )}
                  {confirmModal && (
                    <ConfirmModal
                      confirmModal={confirmModal}
                      setConfirmModal={setConfirmModal}
                    />
                  )}
                  {isModalOpen && (
                    <div>
                      {modalType === "login" ? (
                        <div>
                          <Login
                            isModalOpen={isModalOpen}
                            modalType={modalType}
                            setIsModalOpen={setIsModalOpen}
                            setModalType={setModalType}
                            showModal={showModal}
                            confirmModal={confirmModal}
                            setConfirmModal={setConfirmModal}
                            forgotModal={forgotModal}
                            setForgotModal={setForgotModal}
                          />
                        </div>
                      ) : (
                        <Register
                          isModalOpen={isModalOpen}
                          modalType={modalType}
                          setIsModalOpen={setIsModalOpen}
                          setModalType={setModalType}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {role === "Candidate" ? (
                  <div className="outer-box">
                    {role === "Candidate" ? (
                      <div>
                        {/*    <a
                          href="/cv-manager"
                          class=" theme-btn btn-style-ffive"
                        >
                          {" "}
                          Upload your CV
                    </a>*/}
                        {uploadResume && (
                          <UploadResume
                            uploadResume={uploadResume}
                            setUploadResume={setUploadResume}
                          />
                        )}
                      </div>
                    ) : (
                      <a href="/post-job" className="theme-btn btn-style-ffive">
                        <span className="btn-title">Job Post</span>
                      </a>
                    )}
                    <button
                      class="menu-btn"
                      onClick={() => navigate("/shortlisted-jobs")}
                    >
                      <span class="count">
                        {data[0]?.shortlistedJobs?.length}
                      </span>
                      <span class="icon la la-heart-o"></span>
                    </button>

                    <button
                      class="menu-btn"
                      onClick={() => navigate("/job-alerts")}
                    >
                      <span
                        class="icon la la-bell"
                        style={{ backgroundColor: "orange" }}
                      ></span>
                    </button>

                    <div className="dropdown dashboard-option">
                      <Dropdown overlay={accountMenu} trigger={["click"]}>
                        <a className="dropdown-toggle" role="button">
                          <img
                            src={data[0]?.image}
                            alt="avatar"
                            className="thumb"
                          />

                          <span className="name">My Account</span>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                ) : (
                  <div className="outer-box">
                    {role === "Candidate" ? (
                      {
                        /*     <a href="/cv-manager" class=" theme-btn btn-style-ffive">
                        {" "}
                        Upload your CV
                    </a>*/
                      }
                    ) : (
                      <a href="/post-job" className="theme-btn btn-style-ffive">
                        <span className="btn-title">Job Post</span>
                      </a>
                    )}
                    <button
                      class="menu-btn"
                      onClick={() => navigate("/shortlisted-resumes")}
                    >
                      <span class="count">
                        {" "}
                        {data[0]?.shortlistedResumes?.length}
                      </span>
                      <span class="icon la la-heart-o"></span>
                    </button>

                    <button
                      class="menu-btn"
                      onClick={() => navigate("/resume-alerts")}
                    >
                      <span class="icon la la-bell"></span>
                    </button>

                    <div className="dropdown dashboard-option">
                      <Dropdown overlay={employerMenu} trigger={["click"]}>
                        <a className="dropdown-toggle" role="button">
                          <img
                            src={data[0]?.image}
                            alt="avatar"
                            className="thumb"
                          />

                          <span className="name">My Account</span>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div class="mobile-header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            {/* <div className="logo"> */}
            <a href="/">
              <img src={NewLogo} style={{ height: "50px" }} alt="" title="" />
              {/* <span
                style={{ color: "#1E90FF", fontSize: "18px", fontWeight: 700 }}
              >
                SkillStreamJobs
              </span>
              <span
                style={{
                  color: "#00d0ea",
                  fontSize: "20px",
                  fontWeight: "900",
                }}
              >
                .AI
              </span> */}
            </a>
            {/* <div className="skills-logo">{text}</div> */}
            {/* </div> */}

            <div class="nav-outer clearfix">
              {token === null ? (
                <div className="outer-box">
                  <div className="login-box">
                    <button
                      className="call-modal"
                      // href="/login"
                      onClick={() => showModal("login")}
                    >
                      <span class="icon-user"></span>
                    </button>
                    {forgotModal && (
                      <ForgotPassword
                        forgotModal={forgotModal}
                        setForgotModal={setForgotModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        setModalType={setModalType}
                      />
                    )}
                    {confirmModal && (
                      <ConfirmModal
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                      />
                    )}
                    {isModalOpen && (
                      <div>
                        {modalType === "login" ? (
                          <div>
                            <Login
                              isModalOpen={isModalOpen}
                              modalType={modalType}
                              setIsModalOpen={setIsModalOpen}
                              setModalType={setModalType}
                              showModal={showModal}
                              confirmModal={confirmModal}
                              setConfirmModal={setConfirmModal}
                              forgotModal={forgotModal}
                              setForgotModal={setForgotModal}
                            />
                          </div>
                        ) : (
                          <Register
                            isModalOpen={isModalOpen}
                            modalType={modalType}
                            setIsModalOpen={setIsModalOpen}
                            setModalType={setModalType}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {role === "Candidate" ? (
                    <div class="outer-box">
                      <Drawer
                        onClose={onClose}
                        open={open}
                        destroyOnClose={false}
                        overlay={employerMenu}
                      >
                        <ul class="navigation">
                          <li class="link-option">
                            <Link
                              to="/candidate-dashboard"
                              style={{ color: "black" }}
                            >
                              {" "}
                              <i class="la la-dashboard  design"></i> Dashboard
                            </Link>
                          </li>
                          {/*              <li className="link-option">
                          <Link to="/my-profile" style={{ color: "black" }}>
                            <i class="la la-user-tie  design"></i>My Profile
                          </Link>
                </li>*/}

                          <li className="link-option">
                            <Link
                              to="/practiceInterview"
                              style={{ color: "black" }}
                            >
                              <i class="la la-briefcase design"></i> Practice
                              Video Assessment
                            </Link>
                          </li>
                          <li className="link-option">
                            <Link to="/applied-jobs" style={{ color: "black" }}>
                              <i class="la la-briefcase design"></i> Applied
                              Jobs
                            </Link>
                          </li>
                          <li className="link-option">
                            <button
                              onClick={() => navigate("/job-alerts")}
                              style={{ color: "black" }}
                            >
                              <i class="la la-bell  design"></i>Job Alerts
                            </button>
                          </li>
                          <li className="link-option">
                            <Link
                              to="/shortlisted-jobs"
                              style={{ color: "black" }}
                            >
                              <i class="la la-bookmark-o  design"></i>
                              Shortlisted Jobs
                            </Link>
                          </li>
                          <li className="link-option">
                            <Link to="/cv-manager" style={{ color: "black" }}>
                              <i class="la la-file-invoice  design"></i> CV
                              manager
                            </Link>
                          </li>

                          <li className="link-option">
                            <Link to="/messages" style={{ color: "black" }}>
                              <i class="la la-comment-o  design"></i>Messages
                            </Link>
                          </li>
                          <li className="link-option">
                            <a
                              onClick={() => handleLogout()}
                              style={{ color: "black" }}
                            >
                              <i class="la la-sign-out design"></i>Logout
                            </a>
                          </li>
                        </ul>
                      </Drawer>
                      <button id="toggle-user-sidebar" onClick={showDrawer}>
                        <img src={data[0]?.image} alt="avatar" class="thumb" />
                      </button>
                    </div>
                  ) : (
                    <div class="outer-box">
                      <Drawer
                        onClose={onClose}
                        open={open}
                        destroyOnClose={false}
                      >
                        <ul class="navigation">
                          <li class="link-option">
                            <Link
                              to="/employer-dashboard"
                              style={{ color: "black" }}
                            >
                              {" "}
                              <i class="la la-dashboard  design"></i> Dashboard
                            </Link>
                          </li>

                          {/*  <li className="link-option">
                          <Link
                            to="/company-profile"
                            style={{ color: "black" }}
                          >
                            <i class="la la-user-tie  design"></i>Company
                            Profile
                          </Link>
                </li>*/}
                          <li className="link-option">
                            <Link to="/post-job" style={{ color: "black" }}>
                              <i class="la la-paper-plane design"></i>Post A New
                              Job
                            </Link>
                          </li>
                          <li className="link-option">
                            <Link to="/manage-jobs" style={{ color: "black" }}>
                              <i class="la la-briefcase design"></i> Manage Jobs
                            </Link>
                          </li>
                          <li className="link-option">
                            <button
                              onClick={() => navigate("/resume-alerts")}
                              style={{ color: "black" }}
                            >
                              <i class="la la-bell  design"></i>Notifications
                            </button>
                          </li>
                          <li className="link-option">
                            <Link
                              to="/shortlisted-resumes"
                              style={{ color: "black" }}
                            >
                              <i class="la la-bookmark-o  design"></i>
                              Shortlisted Resumes
                            </Link>
                          </li>
                          <li className="link-option">
                            <Link to="/savedResume" style={{ color: "black" }}>
                              <i class="la la-heart-o  design"></i>
                              Saved Resumes
                            </Link>
                          </li>

                          <li className="link-option">
                            <Link to="/applicants" style={{ color: "black" }}>
                              <i class="la la-file-invoice  design"></i> All
                              Applicants
                            </Link>
                          </li>
                          {/*  <li className="link-option">
                          <Link
                            to="/employer-packages"
                            style={{ color: "black" }}
                          >
                            <i class="la la-box  design"></i>Packages
                          </Link>
                        </li>*/}
                          <li className="link-option">
                            <Link
                              to="/employer-messages"
                              style={{ color: "black" }}
                            >
                              <i class="la la-comment-o  design"></i>Messages
                            </Link>
                          </li>
                          <li className="link-option">
                            <Link
                              to="/employer-profile-view"
                              style={{ color: "black" }}
                            >
                              <i class="la la-comment-o  design"></i>View
                              Profile
                            </Link>
                          </li>
                          <li className="link-option">
                            <a
                              onClick={() => handleLogout()}
                              style={{ color: "black" }}
                            >
                              <i class="la la-sign-out design"></i>Logout
                            </a>
                          </li>
                        </ul>
                      </Drawer>
                      <button id="toggle-user-sidebar" onClick={showDrawer}>
                        <img src={data[0]?.image} alt="avatar" class="thumb" />
                      </button>
                    </div>
                  )}
                </>
              )}
              <button
                className="navbar-trigger"
                onClick={toggleMobileMenu} // Toggle mobile menu state when clicked
              >
                <span className="flaticon-menu-1"></span>
              </button>
            </div>
          </div>
        </div>

        <Drawer
          placement="right"
          closable={true}
          onClose={closeMobileMenu}
          visible={isMobileMenuOpen}
          className="mobile-menu-drawer"
        >
          {/* Drawer content */}
          <nav className="nav main-menu1">
            {/* <div className="mobile-dropdown" id="navbar">
              <div className="current dropdown">
                <a href="/">
                  <span className="mob-nav">Home</span>
                </a>
              </div>
              <div className="dropdown">
                <a href="/about">
                  <span className="mob-nav">About</span>
                </a>
              </div>
              <div className="dropdown">
                <a href="/contact">
                  <span className="mob-nav">Contact</span>
                </a>
              </div>
            </div> */}
            <div className="logo-box1">
              <div className="logo">
                <a href="/">
                  <img
                    src={NewLogo}
                    style={{ height: "45px" }}
                    className="logo-images"
                    alt=""
                    title=""
                  />
                  {/* <span
                    style={{
                      color: "#1E90FF",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    SkillStreamJobs
                  </span>
                  <span
                    style={{
                      color: "#00d0ea",
                      fontSize: "20px",
                      fontWeight: "900",
                    }}
                  >
                    .AI
                  </span> */}
                </a>
                {/* <div className="skills-logo2">{text}</div> */}
              </div>
            </div>
            <div className="mobile-dropdown" id="navbar">
              {token === null ? (
                <>
                  <div className="current dropdown">
                    <a href="/">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        Home
                      </span>
                    </a>
                  </div>
                  <div className="dropdown">
                    <a href="/blog">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        Blog
                      </span>
                    </a>
                  </div>
                  <div className="dropdown">
                    <a href="/community">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        Community
                      </span>
                    </a>
                  </div>
                  <div className="dropdown">
                    <a href="/pricing">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        Pricing
                      </span>
                    </a>
                  </div>
                  <div className="dropdown">
                    <a href="/about">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        About
                      </span>
                    </a>
                  </div>

                  <div className="dropdown">
                    <a href="/contact">
                      <span
                        style={{
                          display:
                            "inline-block" /* Ensures proper spacing and hover effects */,
                          // marginTop:
                          //   "1.5rem" /* Equivalent to Tailwind's mt-6 */,
                          // marginBottom:
                          //   "1rem" /* Equivalent to Tailwind's mb-4 */,
                          fontSize:
                            "0.875rem" /* Equivalent to Tailwind's text-sm */,
                          borderRadius:
                            "9999px" /* Equivalent to Tailwind's rounded-full */,
                          padding:
                            "0.5rem 1rem" /* Adding padding for better clickability */,
                          boxShadow:
                            "0 4px 15px rgba(66, 153, 225, 0.5)" /* Custom Shadow */,
                          transition:
                            "all 0.3s ease-in-out" /* Smooth transition for hover effects */,
                          borderRadius: "10px" /* Custom border radius */,
                          background:
                            "linear-gradient(to right, #4299e1, #0070f3)" /* Custom Gradient */,
                          color: "#fff" /* Text color */,
                          cursor: "pointer" /* Change cursor on hover */,
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0056a1)";
                          e.currentTarget.style.boxShadow =
                            "0 6px 20px rgba(66, 153, 225, 0.7)";
                          e.currentTarget.style.transform = "translateY(-2px)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.background =
                            "linear-gradient(to right, #4299e1, #0070f3)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 15px rgba(66, 153, 225, 0.5)";
                          e.currentTarget.style.transform = "translateY(0)";
                        }}
                      >
                        Contact
                      </span>
                    </a>
                  </div>
                  <div className="resume-button">
                    <button
                      onClick={() => navigate("/candiatesPage")}
                      className="theme-btn btn-style-two1"
                    >
                      <span className="submit-resume">For Job Seekers</span>
                    </button>
                  </div>
                  {/* 
                  <div className="mm-add-listing">
                    <a href="job-post" className="theme-btn btn-style-one">
                      Job Post
                    </a>
                    <span></span>
                  </div> */}
                </>
              ) : (
                <>
                  {role === "Candidate" ? (
                    <>
                      <div
                        className="dropdown has-mega-menu"
                        id="has-mega-menu"
                      >
                        <a href="/find-jobs">
                          <span className="mob-nav">Find Jobs</span>
                        </a>
                      </div>

                      <div className="dropdown">
                        <a href="/employers-list">
                          <span className="mob-nav">Companies</span>
                        </a>
                      </div>
                      <div className="dropdown">
                        <a href="/aiJobSearch">
                          <span className="mob-nav">AI Job Search</span>
                        </a>
                      </div>
                      <div className="dropdown">
                        <a href="/candidate-dashboard">
                          <span className="mob-nav">Dashboard</span>
                        </a>
                      </div>
                      {uploadResume && (
                        <UploadResume
                          uploadResume={uploadResume}
                          setUploadResume={setUploadResume}
                        />
                      )}
                      {/* Add more menu items for candidates */}
                    </>
                  ) : (
                    <>
                      <div className="current dropdown">
                        {data[0]?.plan === "Recommended" ? (
                          <a href="/candidates-list">
                            <span className="mob-nav">Resume Search</span>
                          </a>
                        ) : null}
                      </div>
                      {data[0]?.plan === "Recommended" ? (
                        <div className="current dropdown">
                          <a href="/aiSearch">
                            <span className="mob-nav">AI Resume Search</span>
                          </a>
                        </div>
                      ) : null}

                      <div className="current dropdown">
                        <a href="/employer-dashboard">
                          <span className="mob-nav">Dashboard</span>
                        </a>
                      </div>
                      {/* Add more menu items for employers */}
                    </>
                  )}
                </>
              )}
            </div>
          </nav>
          {token === null && (
            <div className="login-register-button">
              <button className="btn-login" onClick={() => showModal("login")}>
                Login / Register
              </button>
            </div>
          )}
        </Drawer>
      </header>
    </div>
  );
};

export default Header3;
{
  /*
              <div class="dropdown dashboard-option">
                <a
                  class="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src="images/resource/company-6.png"
                    alt="avatar"
                    class="thumb"
                  />
                  <span class="name">My Account</span>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="dashboard.html">
                      {" "}
                      <i class="la la-home"></i> Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-company-profile.html">
                      <i class="la la-user-tie"></i>Company Profile
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-post-job.html">
                      <i class="la la-paper-plane"></i>Post a New Job
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-manage-job.html">
                      <i class="la la-briefcase"></i> Manage Jobs{" "}
                    </a>
                  </li>
                  <li class="active">
                    <a href="dashboard-applicants.html">
                      <i class="la la-file-invoice"></i> All Applicants
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-resumes.html">
                      <i class="la la-bookmark-o"></i>Shortlisted Resumes
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-packages.html">
                      <i class="la la-box"></i>Packages
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-messages.html">
                      <i class="la la-comment-o"></i>Messages
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-resume-alerts.html">
                      <i class="la la-bell"></i>Notifications
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-change-password.html">
                      <i class="la la-lock"></i>Change Password
                    </a>
                  </li>
                  <li>
                    <a href="dashboard-company-profile.html">
                      <i class="la la-user-alt"></i>View Profile
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="la la-sign-out"></i>Logout
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="la la-trash"></i>Delete Profile
                    </a>
                  </li>
                </ul>
              </div>
*/
}
